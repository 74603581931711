import { Routes, linkTo } from '@/utilities/routes';
import { IconType } from 'react-icons';
import { FaFileInvoiceDollar, FaUserTie } from 'react-icons/fa';
import { FaPeopleCarryBox } from 'react-icons/fa6';
import { FiShoppingBag } from 'react-icons/fi';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { IoLogoApple } from 'react-icons/io';
import { IoCalendarNumberOutline } from 'react-icons/io5';
import {
  TbBuildingWarehouse,
  TbCar,
  TbCoin,
  TbMap2,
  TbPackage,
  TbPackageOff,
  TbTruck,
  TbTruckDelivery,
  TbUsers,
} from 'react-icons/tb';
import { TfiKey, TfiMoney } from 'react-icons/tfi';

export type ThingsLink = {
  icon: IconType;
  href: string;
  title: string;
  showInCommandPalette: boolean;
  keyboardShortcut?: {
    modifier: string;
    key: string;
  };
};

export const THINGS_LINKS: ThingsLink[] = [
  {
    icon: FiShoppingBag,
    href: linkTo(Routes.merchants),
    title: 'Merchants',
    showInCommandPalette: true,
    keyboardShortcut: {
      modifier: '⌘',
      key: 'M',
    },
  },
  {
    icon: TbPackage,
    href: linkTo(Routes.shipments),
    title: 'Shipments',
    showInCommandPalette: true,
    keyboardShortcut: {
      modifier: '⌘',
      key: 'S',
    },
  },
  {
    icon: TbTruckDelivery,
    href: linkTo(Routes.externalShipments),
    title: 'Nationwide shipments',
    showInCommandPalette: true,
    keyboardShortcut: {
      modifier: '⌘',
      key: 'N',
    },
  },
  {
    icon: TbCar,
    href: linkTo(Routes.drivers),
    title: 'Drivers',
    showInCommandPalette: true,
    keyboardShortcut: {
      modifier: '⌘',
      key: 'D',
    },
  },
  {
    icon: TbUsers,
    href: linkTo(Routes.users),
    title: 'Users',
    showInCommandPalette: true,
    keyboardShortcut: {
      modifier: '⌘',
      key: 'U',
    },
  },
  {
    icon: TbCoin,
    href: linkTo(Routes.pricing),
    title: 'Pricing',
    showInCommandPalette: true,
  },
  {
    icon: TbPackageOff,
    href: linkTo(Routes.claims),
    title: 'Claims',
    showInCommandPalette: true,
  },
  {
    icon: HiOutlineSpeakerphone,
    href: linkTo(Routes.broadcastMessages),
    title: 'Broadcast Messages',
    showInCommandPalette: true,
  },
  {
    icon: TbMap2,
    href: linkTo(Routes.areaOverrides),
    title: 'Area Overrides',
    showInCommandPalette: true,
  },
  {
    icon: TbBuildingWarehouse,
    href: linkTo(Routes.warehouse),
    title: 'Warehouse',
    showInCommandPalette: true,
  },
  {
    icon: TfiKey,
    href: linkTo(Routes.overrideCode),
    title: 'Today’s Override Code',
    showInCommandPalette: true,
  },
  {
    icon: TfiMoney,
    href: linkTo(Routes.paymentRuns),
    title: 'Payment Runs',
    showInCommandPalette: true,
  },
  {
    icon: IoLogoApple,
    href: linkTo(Routes.employers),
    title: 'Employers',
    showInCommandPalette: true,
  },
  {
    icon: TbTruck,
    href: linkTo(Routes.deliveryVehicles),
    title: 'Delivery Vehicles',
    showInCommandPalette: true,
  },
  {
    icon: FaFileInvoiceDollar,
    href: linkTo(Routes.dspInvoices),
    title: 'DSP Invoices',
    showInCommandPalette: true,
  },
  {
    icon: IoCalendarNumberOutline,
    href: linkTo(Routes.rotas),
    title: 'Rotas',
    showInCommandPalette: true,
  },
  {
    icon: FaPeopleCarryBox,
    href: linkTo(Routes.teams),
    title: 'Teams',
    showInCommandPalette: true,
  },
  {
    icon: FaUserTie,
    href: linkTo(Routes.taskAssignmentRoles),
    title: 'Roles',
    showInCommandPalette: true,
  },
].sort((a, b) => a.title.localeCompare(b.title));
